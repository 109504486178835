*,*::after, *::before{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}
@import './components/project_card';

.test{
    background-color: red;
    height: 100px;
}