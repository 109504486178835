.card {
    position: relative;
    max-width: 350px;
    // height: 190px;
    /* height: 450px; */
    background-color: white;
    border-radius: 20px;
    margin: 50px;
    box-shadow: 0 35px 80px rgba(0, 0, 0, 0.15);
    transition: 0.5s;

    .imgBx {
        position: relative;
        width: 150px;
        height: 150px;
        top: -50px;
        margin: auto auto -50px;

        background: #fff;
        border-radius: 20px;
        box-shadow: 0 15px 50px rgba(0, 0, 0, 0.35);
        overflow: hidden;
        transition: 0.5s;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .content {
        // position: absolute;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        overflow: hidden;

        .details {
            padding: 20px 30px 30px;
            text-align: center;
            width: 100%;
            transition: 0.5s;
            // transform: translateY(150px);

            h2 {
                font-size: 20px;
                font-weight: 600;
                color: #555;
                line-height: 1.2;
                margin-bottom: 10px;
            }

            p {
                font-size: 16px;
                font-weight: 500;
                color: rgba(0, 0, 0, 0.5);
                text-align: justify;
                line-height: 1.3;
            }

            .data {
                display: flex;
                justify-content: space-between;
                margin: 20px 0;

                h3 {
                    font-size: 16px;
                    color: #555;
                    line-height: 1.2;
                    font-weight: 600;
                
                    span {
                        font-size: 14px;
                        font-weight: 400;
                        opacity: 0.5;
                    }
                }
            }

            button {
                margin-top: 15px;
                padding: 10px 30px;
                border-radius: 5px;
                border: none;
                outline: none;
                font-size: 16px;
                font-weight: 500;
                background: #ff5f95;
                color: #fff;
                cursor: pointer;
            }
        }
    }
}