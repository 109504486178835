code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  font-family: 'Open Sans', serif;
  padding-top: 54px;
  color: #868e96;
}

@media (min-width: 992px) {
  body {
    padding-top: 0;
    padding-left: 17rem;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Saira Extra Condensed', serif;
  font-weight: 700;
  text-transform: uppercase;
  color: #343a40;
}

h1 {
  font-size: 6rem;
  line-height: 5.5rem;
}

h2 {
  font-size: 3.5rem;
}

a {
  color: #2196f3;
}

a:hover,
a:focus,
a:active {
  color: #0f72c1;
}

.subheading {
  text-transform: uppercase;
  font-weight: 500;
  font-family: 'Saira Extra Condensed', serif;
  font-size: 1.35rem;
}

/*==================================================
                  SECTION (Generic)
====================================================*/
.heading-border {
  width: 60px;
  height: 10px;
  background-color: #2196f3;
  margin: 0 auto 20px auto;
}

section.about-section {
  border-bottom: 1px solid #dee2e6;
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}

section.about-section .resume-item .resume-date {}

@media (min-width: 768px) {
  section.about-section {
    min-height: 100vh;
  }

  section.about-section .resume-item .resume-date {
    min-width: 18rem;
  }
}

@media (min-width: 992px) {
  section.about-section {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
}

.bg-primary {
  background-color: #2196f3 !important;
}

.text-primary {
  color: #2196f3 !important;
}

/*==================================================
                  SOCIAL-ICON
====================================================*/
.list-social-icons a {
  color: #495057;
}

.list-social-icons a:hover {
  color: #2196f3;
}

.list-social-icons a .fa-lg {
  font-size: 1.75rem;
}

.list-icons {
  font-size: 3rem;
}

.list-icons .list-inline-item i:hover {
  color: #2196f3;
}


@media (min-width: 992px) {
  #sideNav {
    text-align: center;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    width: 17rem;
    height: 100vh;
  }

  #sideNav .navbar-brand {
    display: flex;
    margin: auto auto 0;
    padding: 0.5rem;
  }

  #sideNav .navbar-brand .img-profile {
    max-width: 10rem;
    max-height: 10rem;
    border: 0.5rem solid rgba(255, 255, 255, 0.2);
  }

  #sideNav .navbar-collapse {
    display: flex;
    align-items: flex-start;
    flex-grow: 0;
    width: 100%;
    margin-bottom: auto;
  }

  #sideNav .navbar-collapse .navbar-nav {
    flex-direction: column;
    width: 100%;
  }

  #sideNav .navbar-collapse .navbar-nav .nav-item {
    display: block;
  }

  #sideNav .navbar-collapse .navbar-nav .nav-item .nav-link {
    display: block;
  }
}

/*==================================================
                  BUTTON (Generic)
====================================================*/

.btn-general {
  background-color: transparent;
  text-align: center;
  border-width: 1px;
  border-radius: 0px;
  font-size: 16px;
  padding: 10px 15px;
  font-weight: 400;
  text-transform: uppercase;
}

.btn-white {
  border-color: #2196f3;
  background-color: #2196f3;
  color: #fff;
}

.btn-white:hover,
.btn-white:focus {
  background-color: transparent;
  color: #2196f3;
  cursor: pointer;
}

.btn-green {
  border-color: #2196f3;
  color: #2196f3;
}

.btn-green:hover,
.btn-green:focus {
  background-color: #2196f3;
  color: #fff;
  cursor: pointer;
}