/*==================================================
                      ABOUT
====================================================*/
#about {
    /* background-image: url(/public/img/profile-banner.jpg);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat; */
    background: linear-gradient(-45deg, #e0e0ec, #f8f8ff);
}

#about h1 {
    /*font-size: 100%;*/
}