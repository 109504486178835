
/* --------- */
/* Tech */
/* --------- */

.tech-section {
    position: relative !important;
    padding-top: 150px !important;
    padding-bottom: 30px !important;
    background-image: var(--section-background-color) !important;
    color: #343a40 !important;
  }

  .tech-section .project-heading {
    font-size: 2em;
    font-weight: 700;
    padding-top: 30px;
    padding-bottom: 30px;
    font-family: 'Saira Extra Condensed',
        serif;

}
  
  .tech-icons {
    font-size: 4.5em !important;
    margin: 15px !important;
    padding: 10px !important;
    opacity: 0.93 !important;
    border: 1.7px solid rgba(33, 149, 243, 0.637) !important;
    vertical-align: middle !important;
    text-align: center !important;
    border-radius: 5px !important;
    display: table !important;
    box-shadow: 4px 5px 4px 3px rgba(33, 149, 243,0.137) !important;
    overflow: hidden !important;
    transition: all 0.4s ease 0s !important;
  }
  
  @media (max-width: 767px) {
    .tech-icons {
      margin: 10px !important;
    }
  }
  
  .tech-icons:hover {
    transform: scale(1.05) !important;
    overflow: hidden !important;
    border: 2.2px solid rgba(33, 149, 243, 0.883) !important;
  }
  .tech-icon-images {
    padding: 20px !important;
    line-height: 1.6 !important;
  }
  
  .quote-card-view {
    border: none !important;
    color: white !important;
    background-color: transparent !important;
  }
  
  .about-activity {
    list-style: none !important;
    text-align: left !important;
    padding-left: 1px !important;
  }
  
  @media (max-width: 767px) {
    .about-img {
      padding-top: 0 !important;
    }
  }