/* --------- */
/* Resume */
/* --------- */
.resume-section {
    position: relative !important;
    padding-top: 110px !important;
    padding-bottom: 30px !important;
    background-color: white !important;
    /* color: white !important; */
}

.resume {
    padding-top: 10px;
    padding-bottom: 50px;
}

.resume-left {
    padding-right: 80px !important;
}

.resume-right {
    padding-left: 80px !important;
}

@media (max-width: 767px) {
    .resume-left {
        padding-right: 15px !important;
        padding-left: 15px !important;
    }

    .resume-right {
        padding-right: 15px !important;
        padding-left: 15px !important;
    }
}

.resume .resume-title {
    font-size: 2em;
    font-weight: 700;
    padding-top: 30px;
    padding-bottom: 30px;
    font-family: 'Saira Extra Condensed',
        serif;

}

.resume .resume-item {
    padding: 0 0 10px 25px;
    margin-top: -2px;
    border-left: 2px solid rgba(33, 149, 243,1);
    position: relative;
}

.resume .resume-item .resume-title {
    line-height: 18px;
    font-size: 0.9em;
    background: rgba(33, 149, 243,0.5);
    padding: 8px 15px;
    display: inline-block;
    font-weight: 600;
    margin-bottom: 10px;
    font-family: 'Roboto Condensed',
        sans-serif;
    text-transform: unset;
}

.resume .resume-item ul {
    padding-left: 20px;
    text-align: justify;
}

.resume .resume-item ul li {
    padding-bottom: 10px;
    list-style: none;
}

.resume .resume-item:last-child {
    padding-bottom: 0;
}

.resume .resume-item::before {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 50px;
    left: -9px;
    top: 0;
    background: #fff;
    border: 2px solid rgba(33, 149, 243,1);
}

.like-item {
    padding-top: 10px !important;
    font-size: 1.1em !important;
    font-family: sans-serif !important;
}

.like-btn {
    background-color: #934cce5e !important;
    border-color: #934cce5e !important;
    padding: 0.25rem 0.98rem !important;
    border-radius: 5px !important;
    line-height: 1.4 !important;
    transition: 0.3s ease !important;
}

.like-btn:hover {
    transform: translateY(-2px) !important;
    background-color: #a24dd386 !important;
    border-color: #a24dd386 !important;
}

.animate-like {
    animation-name: likeAnimation;
    animation-fill-mode: forwards;
    animation-duration: 0.85s;
}

@keyframes likeAnimation {
    0% {
        transform: scale(1.5);
    }

    100% {
        transform: scale(1);
    }
}

.fork-btn {
    font-size: 1.1em !important;
    padding-top: 10px !important;
}

.fork-btn-inner {
    line-height: 1.4em !important;
    background-color: #934cce5e !important;
    padding: 0.25rem 1.1rem !important;
    vertical-align: middle !important;
    text-align: center !important;
}

.fork-btn-inner:hover {
    transform: translateY(-2px) !important;
    background-color: #a24dd386 !important;
    border-color: #a24dd386 !important;
}

.fork-btn-inner::after {
    display: none !important;
}